.wrapper {
  @apply py-8;

  width: 100%;
  overflow-x: auto;
  text-align: center;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @screen xl {
    overflow-x: visible;
  }
}

.content {
  @apply gap-x-3 grid-flow-col px-4;

  display: inline-grid;

  @screen md {
    @apply px-6;

    justify-content: center;
  }
}