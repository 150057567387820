.wrap {
  @apply relative z-10 h-41 w-41 bg-white text-sm font-medium transition-colors duration-200 rounded-full border-4 border-white shadow;

  overflow: hidden;
}

.wrap::before {
  @apply absolute z-10 w-112 h-112 bg-white rounded-full transition-all duration-200;

  content: '';
  display: block;
  bottom: 0;
  left: -8.875rem;
  visibility: hidden;
}

.name {
  @apply relative z-20 h-auto mt-0 flex items-center justify-center text-brown transition-spacing duration-200 leading-none;
}

.hoverBottomSemiCircle {
  @apply rounded-full;

  cursor: pointer;

  &:focus {
    @apply ring ring-gray-darker ring-offset-0;

    outline: none;
  }

  &:hover,
  &:focus {
    .wrap {
      @apply bg-red-light;
    }

    .wrap::before {
      bottom: 2.5rem;
      visibility: visible;
    }

    .name {
      @apply mt-1 text-red;
    }
  }
}
