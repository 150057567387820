.wrapper {
  @apply w-full py-32 mb-32;

  display: flex;
  justify-content: center;
  position: relative;
  height: 75vh;

  @screen lg {
    @apply w-full pt-64 pb-80 mb-40;

    align-items: center;
    height: 70vh;
  }

  @screen xl {
    height: 55vh;
  }
}

.categories {
  @apply left-0 -bottom-28;

  position: absolute;
  width: 100%;
}
