.wrapper {
  @apply p-5 shadow bg-white rounded-md;

  &:focus {
    @apply ring ring-gray-darker ring-offset-0;

    outline: none;
  }

  &:hover {
    @apply shadow-lg;
  }

  &.layoutGrid {
    display: flex;
    flex-direction: column;

    .imageWrapper {
      @apply h-52 mb-5;

      position: relative;
    }
  }

  &.layoutList {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .imageWrapper {
      @apply mb-3 w-20 h-14;

      position: relative;

      @screen sm {
        @apply mb-0;
      }

      @screen md {
        @apply w-28 h-20;
      }
    }
  }
}

.label {
  @apply mr-2 text-xs font-medium text-brown-light inline-block;
}

.value {
  @apply font-medium;
}